<template>
  <div id="app" ref="app" :style="width">
    <router-view/>
  </div>
</template>

<script>
import {Stoke} from './api/home'
import wx from "weixin-js-sdk";

export default {
  data() {
    return {
      width: ''
    }
  },
  created() {
    const {clientWidth} = document.documentElement
    if (clientWidth >= 420) {
      this.width = 'width:375px;margin: 0 auto;'
      // this.$refs.app.style.width='375px'
    }

    // 页面每次刷新加载时候都会去读取localStorage里面的vuex状态
    if (localStorage.getItem("store")) {
      this.$store.replaceState(
          Object.assign(
              {},
              this.$store.state,
              JSON.parse(localStorage.getItem("store")) //这里存的是可能经过mutions处理过的state值，是最新的,所以放在最后
          )
      )
    }
    // 在页面刷新之前把vuex中的信息存到sessionStoreage
    window.addEventListener("beforeunload", () => {
      localStorage.setItem("store", JSON.stringify(this.$store.state));
    });

    setTimeout(() => {
      let path = this.$route.path
      if (path !== '/lookwork') {
        // this.$router.push('/lookwork')
        let _id = this.$store.state.userInfo._id
        if (!_id) {
          this.$store.state.userInfo = {}
          this.$router.push('/')
        }
      }
    }, 100)
  },
  mounted() {
    this.test()
  },
  beforeDestroy() {
    localStorage.setItem("store", JSON.stringify(this.$store.state));
  },
  methods: {
    offsetHeight() {
      return this.$refs.app.offsetHeight
    },
    test() {
      let url = window.location.href;
      Stoke({url}).then((res) => {
        let signature = res.signature;
        let noncestr = res.noncestr;
        let timestamp = res.timestamp;
        wx.config({
          appId: "wxa8ce8daa4ddb25f2", // 必填，公众号的唯一标识
          timestamp: timestamp, // 必填，生成签名的时间戳
          nonceStr: noncestr, // 必填，生成签名的随机串
          signature: signature, // 必填，签名
          jsApiList: ["updateAppMessageShareData"], // 必填，需要使用的JS接口列表
        });

        wx.ready(function () {
          //需在用户可能点击分享按钮前就先调用
          wx.updateAppMessageShareData({
            title: "万人添画×失控蚂蚁", // 分享标题
            desc: "旷世的NFT作品，你也能参与其中！", // 分享描述
            link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl:
                "https://hz-mayi.nos-eastchina1.126.net/2021-12-4/OnTime.png", // 分享图标
          });
        });
      });
    },
  }
}
</script>

<style>
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}

#app {
  height: 100%;
}


div::-webkit-scrollbar {
  /*滚动条整体样式*/
  width : 10px;  /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}
div::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 0;
  box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
  background   : #535353;
}
div::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background   : #ededed;
}
</style>
