import Vue from 'vue'
import VueRouter from 'vue-router'


Vue.use(VueRouter)

// const originalPush = VueRouter.prototype.push;
// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err)
// }


const routes = [
  {
    path: '/',
    component: () => import('../views/home'),
    meta: {
      title: "失控蚂蚁",
    },
  },
  {
    path: '/login',
    component: () => import('../views/login'),
    meta: {
      title: "失控蚂蚁",
    },
  },
  {
    path: '/introduce',
    component: () => import('../views/introduce'),
    meta: {
      title: "万人添画 ",
    },
  },
  {
    path: '/law',
    component: () => import('../views/law'),
    meta: {
      title: "法律条款",
    },
  },
  {
    path: '/map',
    component: () => import('../views/map'),
    meta: {
      title: "选择坐标",
    },
  },
  {
    path: '/user',
    component: () => import('../views/user'),
    meta: {
      title: "个人中心",
    },
  },
  {
    path: '/create',
    component: () => import('../views/create'),
    meta: {
      title: "绘画板",
    },
  },
  {
    path: '/exhibit',
    component: () => import('../views/exhibit'),
    meta: {
      title: "万人添画",
    },
  }, {
    path: '/lookwork',
    component: () => import('../views/lookwork'),
    meta: {
      title: "展示作品",
    },
  },
  {
    path: '/test',
    component: () => import('../views/test'),
    meta: {
      title: "失控蚂蚁",
    },
  },
  {
    path: '/createPc',
    component: () => import('../views/createPc'),
    meta: {
      title: "绘画板",
    },
  }

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
